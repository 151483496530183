.app-side-box {
    background-color: var(--left-bar-color) !important;
    top: 0 !important;
    left: 0 !important;
    width: 60px !important;
    height: 100vh !important;
    z-index: 1000 !important;
    align-items: center !important;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    box-shadow: none !important;
    border-right: 1px solid #ccc !important;
  }
  .navigate-side{
    color: #2f4858;
    text-decoration: none;
    /* margin: 10px 0px; */
    cursor: pointer;
  }
  .navigate-side-selected::before {
    content: '';
    position: absolute;
    left: -15px;
    width: 2px; /* Adjust the width of the line as needed */
    background-color: #B08E32; /* Adjust the color of the line as needed */
    height: 30px;

  }
  .side-toolbar{
    width: 100% !important;
  }
  .side-bar-container{
    padding: 0px !important;
    justify-items: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column;
    height: 45%;
  }
  .login-signup-mobile{
    color: black;
    text-decoration: none;
  }
  .sideBar-box{
    display: flex !important;
    justify-content: end !important;
    flex-direction: column !important;
    align-items: center !important;
    position: fixed !important;
    gap: 40px;
    /* left: 1.3%; */
  }

  @media screen and (max-width: 1100px) {
    .app-side-box {
      display: none !important;
    }
    
  }
  
  