/* digitalSidebar.css */
.book-media-link {
  text-decoration: none !important;
  color: black;
}

.video-file-digitalsidebar {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.digital-sidebar-container {
  max-height: 940px;
  overflow-y: scroll;
  transition: width 0.3s ease;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.digital-sidebar-container.closed {
  width: 0px; /* width when closed */
  overflow: hidden;
  padding: 0;
}

.side-toggle-button {
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  position: absolute !important;
  right: -20px;
  top: 180px;
  transform: translateY(-50%);
  border-radius: 0 50% 50% 0 !important;
  padding: 8px !important;
  min-width: 40px !important;
  z-index: 1000;
}

.content-wrapper {
  position: relative;
  width: 300px; /* default width when open */
  transition: all 0.3s ease;
  margin-right: 20px;
}
.content-wrapper.closed {
  width: 0px;
  margin-right: 20px;
}
.audio-file-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.digital-sidebar-container::-webkit-scrollbar {
  width: 6px;
}

.digital-sidebar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.digital-sidebar-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.digital-sidebar-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
