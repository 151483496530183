
.bottom-nav{
    visibility: collapse;
    display: none;
}
.footer-text{
    font-size: 12px !important;
    font-weight: 400 !important;
    margin: 0;
    padding: 0;
    color: #7F7F7F;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: inter !important;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.navigate-bottom{
    color: #2f4858;
    text-decoration: none;
    margin: 0% 6%;
  }

  @media only screen and (max-width: 1100px) {
    .app-footer{
        padding: 10px 0;
    }
    .bottom-nav{
        background-color: var(--left-bar-color) !important;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: center;
        padding: 25px 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        opacity: 1;
        z-index: 1000;
        visibility: visible;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
  }