.search-bar {
    position: relative;
}
.search-input-container {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1;
}
.search-input {
    margin-right: 8px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 0px;
    padding: 8px;
    font-size: 16px;
    transition: border-color 0.3s;
}
.search-input:focus {
    outline: none;
    border-color: #8d711f;
    box-shadow: 0 0 5px rgba(97, 93, 31, 0.5);
}
.search-button {
    margin-right: 8px;
    background: none !important;
    border: none;
    height: 40px;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: #8d711f !important;
}
.close-button {
    margin-left: 8px;
}
