.sidebar-grid {
  transition: all 0.3s ease;
}

.sidebar-grid.minimized {
  min-width: 50px !important;
  padding-right: 20px !important;
}

.main-content-grid {
  transition: all 0.3s ease;
}
