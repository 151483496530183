.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(141, 113, 31, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.modal-content {
    background-color: #f5f5dc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.save-version-button {
    background-color: #8d711f;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.save-version-button:hover {
    background-color: #7a611a;
}
select {
    padding: 6px;
    border: 1px solid #8d711f;
    border-radius: 4px;
    margin: 10px 0;
}
.error {
    color: red;
}
.success {
    color: green;
}