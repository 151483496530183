.action-bar-container {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .action-bar-container.closed {
    transform: translateX(calc(100% - 10px)) translateY(-50%);
  }
  
  .toggle-button {
    position: absolute !important;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 0 0 50% !important;
    padding: 8px !important;
    min-width: 40px !important;
  }
  
  .ActionBar {   
    background-color: #8b6f24;
    height: fit-content;
    width: fit-content;
    border-radius: 20px 0 0 20px;
    display: flex;
    overflow: visible;
    padding: 4px;
  }
  
  /* Add hover effect to make it more interactive */
  .ActionBar IconButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Smooth transition for the toggle */
  .ActionBar {
    transition: transform 0.3s ease-in-out;
  }