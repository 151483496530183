.pdf-container {
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for the PDF pages */
.react-pdf__Page {
  margin: 0 auto;
  max-width: 100%;
}
.react-pdf__Document {
  position: relative;
}

.react-pdf__TextLayer {
  font-family: Arial, sans-serif;
}
.react-pdf__Page__textContent.textLayer {
  display: none;
}
.react-pdf__Page__annotations.annotationLayer {
  display: none;
}
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.prev-button,
.next-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
/* Custom styles for other elements */
.pdf-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.pdf-pages {
  display: flex;
}

.pdf-single-page {
  display: flex;
}

.pdf-double-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pdf-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-50%);
}

.prev-button,
.next-button {
  background-color: transparent;
  border: none;
  font-size: 24px; /* Adjust the size of the arrows */
  cursor: pointer;
  color: #333; /* Change the color as needed */
  padding: 5px;
  transition: color 0.3s ease;
}

.prev-button:disabled,
.next-button:disabled {
  color: #ccc; /* Disabled state color */
  cursor: not-allowed;
}

.prev-button:hover,
.next-button:hover {
  color: #000; /* Hover state color */
}

.page-info {
  position: absolute;
  top: 0px; /* Adjust this value to control how close it is to the bottom */
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  color: #fff; /* Adjust the color as needed */
  background-color: #8d711f; /* Optional: Add a background for better visibility */
  padding: 5px 10px; /* Optional: Add some padding */
  border-radius: 4px; /* Optional: Rounded corners */
  height: 30px;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  z-index: 99;
}

.page-number-input {
  -moz-appearance: textfield;
  appearance: textfield;
  width: 40px; /* Adjust the width as needed */
  text-align: center;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inter !important;
}
.page-number-input::-webkit-outer-spin-button,
.page-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fullscreen-icon {
  background: no-repeat;
  border: none;
}
.fullscreen-icon {
  background: transparent;
  border: none;
}
.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-text {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}
.highlight {
  position: absolute;
  pointer-events: none;
  background-color: rgba(251, 255, 0, 0.973);
}
.left-sidebar {
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
}
