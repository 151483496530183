/* userBooks.css */

/* Header styles */
/* Add styles for the header component if needed */

/* Container for user's books */
.user-books-container {
  display: flex;
  align-items: center;
  align-items: flex-start !important;
  height: 100% !important;
  position: relative;
  z-index: 1;
  }
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .search-input {
    width: 98%; /* Adjust the width as needed */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
  }

  .books-info{
    width: 100%;
    padding: 20px;
  }
  
  /* Optional: Add some styles for the placeholder text */
  .search-input::placeholder {
    color: #999;
  }
  /* Book cards */
  .book-card {
    padding: 10px;
    text-align: center;
    position: relative;
    justify-content: center;
    display: flex;
  }

  .user-books-title{
    color: #2F4858;
    font-weight: 700 !important;
    font-size: 25px !important;
    font-family: inter !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .book-grid{
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,.03), inset 0 0 0 1px rgba(0,0,0,.03), 0 1px 2px rgba(0,0,0,.05);
    border-radius: 5px;
    padding: 20px;
    display: block;
  } 
  
  .book-grid:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  
  .book-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .book-title-div{
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .book-title {
    color: #2F4858;
    font-weight: 700 !important;
    font-size: 15px !important;
    font-family: inter !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-top: 10px !important;
  }

  .book-main-grid{
    padding: 16px;
  }
  .book-expiry-date{
    color: #2F4858;
    font-size: 15px !important;
    font-family: inter !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-top: 10px !important;
    text-align: center;
  }
  .expiry-date{
    text-decoration: none !important;
    font-size: 10px;
    font-style: italic;
  }
  .book-link{
    text-decoration: none !important;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .book-main-grid {
      margin-bottom: 15px !important;
    }
    .search-input{
      width: 90%;
      margin: 0px;
    }
  }
  

  