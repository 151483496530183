/* Add these styles to your CSS file or style section in your component */

.user-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .user-profile-header-container{
    display: flex;
    align-items: center;
    align-items: flex-start !important;
    height: 100% !important;
    position: relative;
    z-index: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  }
  
  .user-profile-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 50%;
  }
  
  .user-profile-header {
    color: #333333;
    margin-bottom: 15px;
  }
  
  .user-profile-details {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .user-profile-button{
    width: 30%;
    padding: 8px !important;
    margin-bottom: 15px !important;
    border: 1px solid #cccccc !important;
    border-radius: 4px !important;
    background-color: #2F4858 !important;
    color: #fff !important;
    font-weight: 700 !important;
    cursor: pointer !important;
  }

  .password-management-details{
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  
  .user-profile-label {
    margin-bottom: 5px;
    color: #666666;
  }
  
  .user-profile-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .user-profile-input-email{
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #f2f2f2;
  }
  
  .user-avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .user-profile-hr {
    width: 99%;
    border: 1px solid #dddddd;
  }
  
  .password-management-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 50%;
  }
  
  /* Responsive Styles (adjust as needed) */
  @media (max-width: 768px) {
    .user-profile-box,
    .password-management-box {
      width: 90%;
    }
  }
  