/* Style for form container */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    height: 100vh; /* Set height to 100% of viewport height */
    width: 100%; /* Set width to 100% of the parent container */
    margin: 0; /* Remove default margin */
    padding: 0;
  }
  
  /* Style for form input */
  .book-code-input {
    /* width: 300px; */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Style for the success message */
  .success-message {
    color: green;
    margin-bottom: 10px;
    text-align: center; /* Centers the text */
    display: block;

  }
  
  /* Style for the error message */
  .error-message {
    color: red;
    margin-bottom: 10px;
    text-align: center; /* Centers the text */
    display: block;

  }
  
  /* Style for the submit button */
  .submit-form-button {
    padding: 10px 20px;
    background-color: #B08E32;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }
  
  .submit-form-button:hover {
    background-color: #B08E32;
  }
  
  /* Style for the "Add more books" button container */
  .add-more-books {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  /* Style for the "Add more books" button */
  .add-more-books-button {
    padding: 10px 20px;
    background-color: #B08E32;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }

  .return-to-library {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #B08E32 !important;
    color: gray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  /* Style for the form container background and shadow */
  .form-container form {
    background-color: #fff; /* Background color */
    padding: 20px; /* Padding around the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    width: 21%;
    position: relative;
    top: -70px;
  }

  .activate-book-title{
    color: #2F4858;
    font-weight: 700 !important;
    font-size: 25px !important;
    font-family: inter !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .codes-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-start !important;
    height: 100% !important;
    position: relative;
    z-index: 1;
  }

  .instructions{
    color: #2F4858;
    font-weight: 700 !important;
    font-size: 15px !important;
    font-family: inter !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  @media screen and (max-width: 768px) {
    .form-container form {
      width: auto;
    }
  }
  