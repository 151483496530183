.page-mask-curtain {
  position: absolute;
  left: 0;
  width: 100%;
  background-color:rgb(139, 111, 36);
  transition: height 0.1s ease-out;
  pointer-events: auto;
}

.top-curtain {
  top: 0;
}

.bottom-curtain {
  bottom: 0;
}

.pull-cord {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 20px;
  background-color: rgba(139, 111, 36, 0.8);
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ns-resize;
}

.top-curtain .pull-cord {
  bottom: -20px;
}

.bottom-curtain .pull-cord {
  top: -20px;
  transform: translateX(-50%) rotate(180deg);
}

.icon {
  pointer-events: none;
}

.dragging {
  transition: none;
}